<template>
  <div id="favorites-page">
    <AppContent
      class="favorites-content"
      :isFullPage="true"
      :isFluid="false"
      :isContentContainer="true"
      :withSidebar="true">
      <h2>Favorites</h2>
      <br>
      <b-tabs
        nav-class="fave-tabs"
        active-nav-item-class="active-fave-tabs"
        justified>
        <b-tab title="Videos" active lazy>
          <Videos />
        </b-tab>
        <b-tab title="Music" lazy>
          <Music />
        </b-tab>
      </b-tabs>
      
      <!-- <Videos />
      <br><br>
      <Music />
      <br> -->
    </AppContent>
  </div>
</template>

<script>
  export default {
    components : {
      'AppContent' : () => import('@/components/layout/AppContent'),
      'Videos'     : () => import('./Videos'),
      'Music'      : () => import('./Music'),
    },
    mounted() {
      this.$store.dispatch('profile/getVideoFavorites');
      this.$store.dispatch('profile/getMusicFavorites');
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  #favorites-page {
    background-image: $slide-3;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    min-height: 100vh;
  }

  /* Extra Small devices */
  @media only screen and (max-width: $xs-max) {}
  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {}
  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {}
  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {
    #favorites-page {
      padding-left: 8rem;
    }
    .favorites-content {
      margin: 0 auto;
      width: 55em;
    }
  }
  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
    #favorites-page {
      padding-left: 5rem;
    }
    .favorites-content {
      margin: 0 auto;
      width: 70em;
    }
  }
  @media only screen and (min-width: $special) {
    .favorites-content {
      margin: 0 auto;
      width: 70em;
    }
  }
  @media only screen and (min-width: $xl2-min) {
    .favorites-content {
      margin: 0 auto;
      width: 80em;
    }
  }
  /* iMac Res */
  @media only screen and (min-width: $xl5-min) {
    .favorites-content {
      margin: 0 auto;
      width: 85em;
    }
  }
</style>